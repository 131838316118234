import React, { createContext, useContext, useCallback } from 'react';
import { ethers } from 'ethers';
import { useAuth } from './AuthContext';
import { useUser } from './UserContext';
import { showToast } from '../components/common/Toast';

interface WalletContextType {
  isWalletInstalled: boolean;
  linkWallet: () => Promise<void>;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { session, user } = useAuth();
  const { refreshUser } = useUser();
  const detectEthereumProvider = () => {
    const { ethereum } = window;
    return !!ethereum;
  };

  const linkWallet = useCallback(async () => {
    try {
      if (!window.ethereum) {
        showToast({ 
          message: 'Please install a ETH wallet to link your wallet', 
          type: 'error' 
        });
        return;
      }

      if (!session?.access_token || !user?.id) {
        showToast({ 
          message: 'You must be logged in to link a wallet', 
          type: 'error' 
        });
        return;
      }

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      const message = `Permanently link wallet to Teneo account: ${user.id} This can only be done once.`;
      const signature = await signer.signMessage(message);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/link-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: JSON.stringify({
          address,
          signature,
          message
        })
      });

      const data = await response.json();
      
      if (!data.success) {
        showToast({ 
          message: data.message || 'Failed to link wallet', 
          type: 'error' 
        });
        return;
      }

      showToast({ 
        message: 'Wallet linked successfully!', 
        type: 'success' 
      });
      await refreshUser();
      
    } catch (err) {
      console.error('Wallet linking error:', err);
      showToast({
        message: err instanceof Error && err.message.includes('user rejected action (action="signMessage"')
          ? 'You rejected the signature request'
          : err instanceof Error ? err.message : 'Failed to link wallet',
        type: 'error'
      });
    }
  }, [session, user, refreshUser]);

  return (
    <WalletContext.Provider
      value={{
        isWalletInstalled: detectEthereumProvider(),
        linkWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
}; 