import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';

// Add interface for user stats
interface UserStats {
  dailyPoints: number;
  totalPoints: number;
  totalHeartbeats: number;
  pointsBreakdown: {
    category: string;
    percentage: number;
    color: string;
  }[];
  // Add other stats as needed
}

interface UserContextType {
  userData: {
    wallet?: string;
    [key: string]: any;
  };
  userStats: UserStats | null;
  loading: boolean;
  refreshUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<any>(null);
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (user) {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();
        
      if (error) {
        console.error('Error fetching user data:', error);
      } else {
        setUserData(data);
      }
    }
    setLoading(false);
  };

  const fetchUserStats = async () => {
    try {
      setLoading(true);
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) {
        console.log('No session found');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/stats`, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch stats');
      const rawStats = await response.json();
      
      const stats: UserStats = {
        dailyPoints: rawStats.points_today,
        totalPoints: rawStats.points_total,
        totalHeartbeats: rawStats.heartbeats,
        pointsBreakdown: rawStats.points_breakdown
      };
      setUserStats(stats);
    } catch (error) {
      console.error('Error fetching user stats:', error);
    } finally {
      setLoading(false);
    }
  };

  const refreshUser = async () => {
    // Fetch both user data and stats
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();
      setUserData(data);
      await fetchUserStats();
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchUserData(),
          fetchUserStats()
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchAllData();
  }, []);

  return (
    <UserContext.Provider value={{ userData, userStats, loading, refreshUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}; 